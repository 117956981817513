import { SET_PRODUCTS, SET_PRODUCT_CATEGORIES } from "./types"

export const initialState = {
  products: [],
  productCategories: [],
}

let reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCTS:
      return { ...state, products: action.payload }
    case SET_PRODUCT_CATEGORIES:
      return { ...state, productCategories: action.payload }
    default:
      return { ...state }
  }
}

export default reducer
