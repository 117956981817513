import React, { useContext, useEffect } from "react"
import { Link } from "gatsby"
import { CartContext } from "../contexts/Cart"
import { StoreContext } from "../contexts/Store"
import { HiOutlineShoppingCart } from "react-icons/hi"
import { HiShoppingCart } from "react-icons/hi"
import { GrClose, GrMenu } from "react-icons/gr"
import { MenuLinks } from "./index"
import api from "../utils/api"

import * as styles from "./Header.module.scss"

const Header = ({ toggleMobileMenu = () => null, mobileMenu = false }) => {
  const {
    cart: { items },
  } = useContext(CartContext)

  const {
    actions: { setProducts, setProductCategories },
  } = useContext(StoreContext)

  useEffect(() => {
    api("products").then(res => {
      if (res && res.products && res.products.length > 0)
        setProducts(res.products)
      if (res && res.productCategories && res.productCategories.length > 0)
        setProductCategories(res.productCategories)
    })
  }, [])

  return (
    <header className={styles.header}>
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light bg-transparent px-0">
          <Link className="navbar-brand" to={"/"}>
            <img
              className={styles.logo}
              src={require("../images/logo.png").default}
              alt="Welcome to Owita Organics"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleMobileMenu}
          >
            {mobileMenu ? <GrClose /> : <GrMenu />}
          </button>
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav ms-auto mr-auto">
              <MenuLinks />
            </ul>

            <Link
              to={"/cart"}
              className={`btn ${styles.cart_icon} ${items.length > 0 ? "text-theme" : ""
                }`}
            >
              {items.length > 0 && <span>({items.length})</span>}
              {items.length === 0 ? (
                <HiOutlineShoppingCart />
              ) : (
                <HiShoppingCart />
              )}
            </Link>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header
