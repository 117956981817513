import React, { useContext } from "react"
import * as styles from "./Footer.module.scss"
import { BsArrowRightShort } from "react-icons/bs"
import { Link } from "gatsby"
import contact from "../config/contact"
import { StoreContext } from "../contexts/Store"

const Footer = () => {
  const {
    store: { productCategories },
  } = useContext(StoreContext)

  const dt = new Date()
  return (
    <footer>
      <div className={`container ${styles.footer_main_wrapper}`}>
        <hr />
        <div className="row">
          <div className="col-sm-6 col-xl-4">
            <img src={require("../images/logo.png").default} alt="Welcome to Owita" />
            <p>
              Owita naturals ist die ideale Erfüllung für Sie um biologische,
              frische und gesunde Lebensmitteln aus Sri Lanka zu geniessen.
            </p>
          </div>
          <div className="col-sm-6 col-xl-3">
            <h4>Bio-Laden</h4>
            <ul>
              {productCategories.map(({ slug, name }) => {
                return (
                  <li key={slug}>
                    <Link to={`/organic-store/${slug}`}>
                      <BsArrowRightShort /> {name}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="col-sm-6 col-xl-3">
            <h4>Information</h4>
            <ul>
              {/*<li>*/}
              {/*  <Link to={"/delivery-information"}>*/}
              {/*    <BsArrowRightShort /> Lieferinformationen*/}
              {/*  </Link>*/}
              {/*</li>*/}
              <li>
                <Link to={"/privacy-policy"}>
                  <BsArrowRightShort /> Datenschutzbestimmungen
                </Link>
              </li>
              <li>
                <Link to={"/refund-policy"}>
                  <BsArrowRightShort /> Rückerstattung
                </Link>
              </li>
              {/*<li>*/}
              {/*  <Link to={"/online-orders"}>*/}
              {/*    <BsArrowRightShort /> Online Bestellung*/}
              {/*  </Link>*/}
              {/*</li>*/}
            </ul>
          </div>
          <div className="col-sm-6 col-xl-2">
            <h4>Kontakt</h4>
            <ul>
              <li>
                <a href="tel:+41763715310">
                  <BsArrowRightShort /> +41 76 371 53 10
                </a>
              </li>
              <li>
                <a href={`mailto:${contact.email}`}>
                  <BsArrowRightShort /> {contact.email}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <section className={styles.footer_bar}>
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex align-items-center justify-content-center justify-content-md-start">
              <p>
                Copyrights © 2017 - {dt.getFullYear()}. All Rights Reserved.
              </p>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-center justify-content-md-end"></div>
          </div>
        </div>
      </section>
    </footer>
  )
}

export default Footer
