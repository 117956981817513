import React from "react"
import PropTypes from "prop-types"
import Layout from "./src/Layout"
import { toast, Flip } from "react-toastify"
import { PayPalScriptProvider } from "@paypal/react-paypal-js"

import { CartContextProvider } from "./src/contexts/Cart"
import { StoreContextProvider } from "./src/contexts/Store"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./src/styles/theme/index.scss"
import "react-toastify/dist/ReactToastify.css"
import "./src/styles/base.scss"

toast.configure({
  autoClose: 5000,
  hideProgressBar: true,
  position: toast.POSITION.TOP_CENTER,
  transition: Flip,
  theme: "light",
})

export const wrapRootElement = ({ element }) => (
  <CartContextProvider>
    <StoreContextProvider>
      <Layout>
        <PayPalScriptProvider
          options={{
            "client-id": process.env.GATSBY_PAYPAL_CLIENT_ID,
            currency: "CHF",
          }}
        >
          {element}
        </PayPalScriptProvider>
      </Layout>
    </StoreContextProvider>
  </CartContextProvider>
)

wrapRootElement.propTypes = {
  element: PropTypes.isRequired,
}
