exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-cart-index-js": () => import("./../../../src/pages/cart/index.js" /* webpackChunkName: "component---src-pages-cart-index-js" */),
  "component---src-pages-cart-paypal-button-jsx": () => import("./../../../src/pages/cart/PaypalButton.jsx" /* webpackChunkName: "component---src-pages-cart-paypal-button-jsx" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-delivery-information-index-js": () => import("./../../../src/pages/delivery-information/index.js" /* webpackChunkName: "component---src-pages-delivery-information-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-online-orders-index-js": () => import("./../../../src/pages/online-orders/index.js" /* webpackChunkName: "component---src-pages-online-orders-index-js" */),
  "component---src-pages-organic-store-js": () => import("./../../../src/pages/organic-store.js" /* webpackChunkName: "component---src-pages-organic-store-js" */),
  "component---src-pages-our-farms-index-js": () => import("./../../../src/pages/our-farms/index.js" /* webpackChunkName: "component---src-pages-our-farms-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-refund-policy-index-js": () => import("./../../../src/pages/refund-policy/index.js" /* webpackChunkName: "component---src-pages-refund-policy-index-js" */)
}

