import React, { useState } from "react"
import Header from "./Header"
import Footer from "./Footer"
import { Link } from "gatsby"
import * as styles from "./index.module.scss"

const Layout = ({ children }) => {
  const [mobileMenu, setMobileMenu] = useState(false)

  const toggleMobileMenu = () => {
    setMobileMenu(!mobileMenu)
  }

  return (
    <div className={"web-container"}>
      <Header toggleMobileMenu={toggleMobileMenu} mobileMenu={mobileMenu} />
      {mobileMenu ? (
        <MobileMenu />
      ) : (
        <>
          <main>{children}</main>
          <Footer />
        </>
      )}
    </div>
  )
}

const MobileMenu = () => {
  return (
    <div className={styles.mobile_menu}>
      <div className="container">
        <ul>
          <MenuLinks />
          <li className="nav-item">
            <Link className="nav-link" to="/cart" activeClassName={"active"}>
              Warenkorb
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export const MenuLinks = () => {
  return (
    <>
      <li className="nav-item">
        <Link className="nav-link" to="/" activeClassName={"active"}>
          Owita-Welt
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/our-farms" activeClassName={"active"}>
          Unsere Farmen
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/about-us" activeClassName={"active"}>
          Über uns
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/contact-us" activeClassName={"active"}>
          Kontakt
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className="nav-link"
          to="/organic-store"
          activeClassName={"active"}
        >
          Bio-Laden
        </Link>
      </li>
    </>
  )
}

export default Layout
