import axios from "axios"

const baseUrl =
  process.env.NODE_ENV === "production"
    ? "https://admin.owitanaturals.ch"
    : "https://admin.owitanaturals.ch"

const api = (endpoint = "", data = {}) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  }

  // options.body = JSON.stringify(data)
  return axios({
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    url: `${baseUrl}/api/${endpoint}`,
    headers,
    data,
  }).then(res => res.data).catch(e => console.log(e))
}

export default api
